import React from 'react';
import logo from './logopele.png'; // Assurez-vous que le fichier logopele.png existe dans le dossier `src`
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Bienvenue sur la plateforme de réservation des Pèlerinages du Diocèse de Gap-Embrun</h1>
        <p>
          Cette interface servira à gérer les pèlerinages.
        </p>
        <a
          className="App-link"
          href="https://pelerinagesdegap.fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          En savoir plus sur les Pèlerinages de Gap
        </a>
      </header>
    </div>
  );
}

export default App;
